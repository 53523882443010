




































































































































































































import { Mixin_list } from "@/mixin";
import { api_user, user } from "@/http/user";
import { api_gifts } from "@/http/gifts";
import { Component } from "vue-property-decorator";
import Page from "@/components/page/index.vue";
import DatePicker from "@/components/datePicker/index.vue";
import Table from "@/components/table/index.vue";
import { api_store, store } from "@/http/store";
import { api_meal, meal } from "@/http/meal";
@Component({
  components: {
    Page,
    Table,
    DatePicker,
  },
})
export default class extends Mixin_list<user>(api_user.get_list) {
  filter = {
    id: "",
    phone: "",
    nickname: "",
    user_type: "",
    create_time_before: "",
    create_time_after: "",
    status: "",
  };
  resetFilter = {
    ...this.filter,
  };
  columns = [
    {
      label: "ID",
      prop: "id",
      width: 80,
    },
    {
      label: "头像",
      slot: "avatar_url",
      prop: "avatar_url",
      width: 80,
    },
    {
      label: "用户名",
      prop: "nickname",
    },
    {
      label: "手机号",
      prop: "phone",
    },
    {
      label: "账户余额",
      prop: "wallet",
    },
    {
      label: "会员卡",
      prop: "member_name",
    },
    {
      label: "店铺名称",
      prop: "",
      slot: "store",
      width: 120,
    },

    {
      label: "创建时间",
      prop: "create_time",
    },
    {
      label: "操作",
      prop: "",
      width: 300,
      slot: "operation",
    },
  ];

  typeList = [
    {
      label: "全部",
      value: "",
    },
    {
      label: "微信",
      value: 1,
    },
    {
      label: "抖音",
      value: 2,
    },
  ];

  statusList = [
    {
      label: "全部",
      value: "",
    },
    {
      label: "禁止",
      value: 0,
    },
    {
      label: "正常",
      value: 1,
    },
  ];
  onUpdateRange([startStr, endStr]: [string, string]) {
    this.filter.create_time_after = startStr;
    this.filter.create_time_before = endStr;
  }
  reset() {
    this.filter = { ...this.filter, ...this.resetFilter };
    (this.$refs.datePicker as Vue & { clearDates: () => void }).clearDates();
    this.find();
  }
  handleClick() {}
  // async handleClick( row:user){
  //     this.$confirm('确认升级吗?').then(async()=>{
  //         await api_user.edit({id:row.id,user_level:row.user_level === 1 ? 2 : row.user_level === 2 ? 3 : 0})
  //         this.get_list()
  //     })
  // }

  click_cell(row: user) {
    // return this.$router.push(`/user/detail/?id=${row.id}`);
  }
  // 获取头像
  get_avatar(value: string) {
    // 如果头像包含http，直接返回
    if (value.includes("http")) {
      return value;
    } else {
      return `https://images.xiyuebiaoju.com/${value}`;
    }
  }
  // 赠送余额
  async give_money(row: user) {
    // 定义PromptResult类型来精确描述$prompt的返回值结构
    // type PromptResult = {
    //   value: string | null;
    //   action: string | null;
    // };

    // 获取余额
    const promptResult: any = await this.$prompt("请输入赠送金额", "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      inputPattern: /^[0-9]*$/, // 确保输入是数字字符
      inputErrorMessage: "请输入数字",
    });
    console.log(promptResult);
    // 检查用户是否点击了确定按钮并且提供了有效输入
    if (promptResult.value) {
      try {
        // 将输入的字符串转换为数字，这里假设输入始终是有效的数字字符串
        const amount = parseInt(promptResult.value, 10);
        // 确保转换成功，避免将非数字的字符串传递给API
        if (!isNaN(amount)) {
          await api_gifts.give_gifts({ user_id: Number(row.id), amount,store:Number(row.store) });
          this.get_list();
        } else {
          // 如果转换失败，可以处理错误，比如提示用户输入不合法
          this.$message.error("输入的金额无效，请输入数字。");
        }
      } catch (error) {
        // 处理任何其他潜在错误
        console.error("处理赠礼金额时发生错误:", error);
        this.$message.error("处理赠礼时出现错误。");
      }
    }
  }
  // 清空余额
  async clear_balance(row: user) {
    this.$confirm("确认清空余额吗?").then(async () => {
      await api_user.edit({ id: row.id, wallet: 0,user_level:0 });
      this.get_list();
    });
  }
  // 获取门店列表
  storeList: any[] = [];
  async get_store_list() {
    const res = await api_store.get_list({ page_size: 10000 });
    this.storeList = res.results;
  }

  created() {
    this.get_store_list();
  }
  // 获取店铺名称
  get_store(value: string) {
    return this.storeList.find((item: any) => item.id === value)?.name || '---';
  }
  // 修改会员卡
  dialogVisible = false;
  userId = 0;
  openDialog(row: any) {
    // console.log(row)
    this.userId = row.id;
    this.user_level = row.user_level;
    this.dialogVisible = true;
    this.get_user_level__list()
  }
  handleClose(done: any) {
    this.$confirm("确认关闭？")
      .then((_) => {
        done();
      })
      .catch((_) => {});
  }
  // 获取会员卡列表
  userLevelList: any[] = [];
  // 会员等级
  user_level: Number = 0;
  async get_user_level__list() {
    const res = await api_meal.get_list({ page_size: 10000 });
    const list: any[] = [];
    res.results.forEach((item: any) => {
      (item.value = item.id), (item.label = item.name);
    });
    this.userLevelList = list.concat(res.results);
  }
  editUserLevel() {
    this.$confirm("确认修改会员卡吗?").then(async () => {
      await api_user.edit({ id: this.userId, user_level: this.user_level });
      this.get_list();
      this.dialogVisible = false;
    });
  }
}
